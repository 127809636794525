<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode" />
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{ title }}</div>
        <div class="formBody">
          <!-- 表单 -->
          <van-form @submit="onSubmit">
            <MyPatient
              :memberId="memberId"
              :selectValue="selectValue"
              @change="userChange"
            />
            <van-field
              v-model="jisuanfangshi"
              :rules="[{ required: true, message: '请选择' }]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">计算方式 <i>*</i></span>
                  <span @click="jisuanClick">
                    <span class="information1">
                      <span
                        class="PleaseSelect"
                        :style="{
                          color:
                            jisuanfangshi === ''
                              ? 'rgb(166, 166, 166)'
                              : 'black',
                        }"
                        >{{
                          jisuanfangshi === "" ? "请选择" : jisuanfangshi
                        }}</span
                      >
                    </span>
                    <span>
                      <van-icon
                        name="arrow"
                        style="margin-left: 5px"
                        color="#A7A7A7"
                        size="17px"
                    /></span>
                  </span>
                </div>
              </template>
            </van-field>
            <van-field
              v-if="this.jisuanfangshi == '按体重减轻'"
              v-model="yuantizhong"
              :rules="[
                { required: true, message: '请填写' },
                {
                  pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/,
                  message: '请填写数字',
                },
              ]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">原体重 <i>*</i></span>
                  <span style="display: flex;align-items: center;">
                    <span class="information">
                      <van-cell-group class="vanCell">
                        <van-field v-model="yuantizhong" placeholder="请输入" />
                      </van-cell-group>
                    </span>
                    <div class="unit"><span>kg</span></div>
                  </span>
                </div>
              </template>
            </van-field>
            <van-field
              v-if="
                this.jisuanfangshi == '按体重减轻' ||
                  this.jisuanfangshi == '按血细胞比容' ||
                  this.jisuanfangshi == '按血钠浓度'
              "
              v-model="xiantizhong"
              :rules="[
                { required: true, message: '请填写' },
                {
                  pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/,
                  message: '请填写数字',
                },
              ]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">现体重 <i>*</i></span>
                  <span style="display: flex;">
                    <span class="information">
                      <van-cell-group class="vanCell">
                        <van-field v-model="xiantizhong" placeholder="请输入" />
                      </van-cell-group>
                    </span>
                    <div class="unit"><span>kg</span></div>
                  </span>
                </div>
              </template>
            </van-field>
            <van-field
              v-if="this.jisuanfangshi == '按血细胞比容'"
              v-model="xuexibaobirong"
              :rules="[
                { required: true, message: '请填写' },
                {
                  pattern: /^(0(\.\d{1,2})?|1(\.0{1,2})?)$/,
                  message: '请填写0~1之间的数字',
                },
              ]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">血细胞比容 <i>*</i></span>
                  <span style="display: flex;">
                    <span class="information">
                      <van-cell-group class="vanCell">
                        <van-field
                          v-model="xuexibaobirong"
                          placeholder="请输入"
                        />
                      </van-cell-group>
                    </span>
                    <!-- <div class="unit"><span>kg</span></div> -->
                  </span>
                </div>
              </template>
            </van-field>
            <van-field
              v-if="this.jisuanfangshi == '按血钠浓度'"
              v-model="xuenanongdu"
              :rules="[
                { required: true, message: '请填写' },
                {
                  pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/,
                  message: '请填写数字',
                },
              ]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">血钠<i>*</i></span>
                  <span style="display: flex;">
                    <span class="information">
                      <van-cell-group class="vanCell">
                        <van-field v-model="xuenanongdu" placeholder="请输入" />
                      </van-cell-group>
                    </span>
                    <div class="unit"><span>mmol/L</span></div>
                  </span>
                </div>
              </template>
            </van-field>
            <van-field
              v-if="
                this.jisuanfangshi == '按血钠浓度' ||
                  this.jisuanfangshi == '按血细胞比容'
              "
              v-model="patientSex"
              :rules="[{ required: true, message: '请选择' }]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">性别 <i>*</i></span>
                  <van-radio-group v-model="patientSex" direction="horizontal">
                    <van-radio name="1">男</van-radio>
                    <van-radio name="2">女</van-radio>
                  </van-radio-group>
                </div>
              </template>
            </van-field>
            <div
              v-if="compute === true"
              style="margin: 16px;display: flex;justify-content: center;"
            >
              <van-button
                class="formula-template-button"
                block
                type="info"
                native-type="submit"
                style="width:90px"
                >提交</van-button
              >
            </div>
          </van-form>
          <!-- 计算结果 -->
          <!-- <div class="CalculationResultsBox" v-if="Submitted">
              <div class="CalculationResults"><span>计算结果</span></div>
              <div>
                <div class="CalculationDetail"><span></span>根据体重减轻量估算：{{ this.tizhongjianqingBYL }} ml</div>
              </div>
              <div v-if="this.patientSex == 1">
                <div class="CalculationDetail"><span></span>根据血细胞比容：{{ this.xuexibaobirongNanBYL }} ml</div>
              </div>
              <div v-if="this.patientSex == 2">
                <div class="CalculationDetail"><span></span>根据血细胞比容：{{ this.xuexibaobirongNvBYL }} ml</div>
              </div>
              <div  v-if="this.patientSex == 1">
                <div class="CalculationDetail"><span></span>根据血钠浓度计算：{{ this.xuenanongduNanBYL }} ml</div>
              </div>
              <div  v-if="this.patientSex == 2">
                <div class="CalculationDetail"><span></span>根据血钠浓度计算：{{ this.xuenanongduNvBYL }} ml</div>
              </div>
            </div> -->
        </div>
      </div>
      <!-- 计算结果 -->
      <CalculationResults
        v-if="show"
        :countText="countText"
        :countTextList="countTextList"
      ></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="StandardDescription"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences :references-data="references"></FormulaReferences>
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          title="应激指数"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import {
  getFormula,
  getFormulaHuiXian,
  postFormula,
} from "@/service/BodyMassIndex_api"; //
import MyPatient from "@/components/MyPatient.vue";
import FormulaBanner from "@/components/FormulaBanner.vue";
import router from "@/router";
import { Toast } from "vant";
import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "../../../components/template1/FormulaReferences.vue";
import CalculationResults from "@/components/CalculationResults";
// import { result } from "lodash";
export default {
  name: "medicalSearch",
  data() {
    return {
      compute: true,
      show: false,
      columns: ["按体重减轻", "按血细胞比容", "按血钠浓度"],
      jisuanfangshi: "",
      countTextList: [],
      countText: "",
      formulaCode: "",
      xuexibaobirong: "",
      xuenanongdu: "",
      patientSex: "",
      nanxing: "",
      nvxing: "",
      columnCode: "",
      formulaId: "",
      title: "",
      memberId: "1111",
      patientId: null,
      selectValue: "",
      xiantizhong: "",
      yuantizhong: "",
      showPopover: false,
      StandardDescription: [],
      FormulaResult: "",
      references: [],
      zhongguo: "",
      guoji: "",
      xuwenshengshi: "",
      zhongguotongyong: "",
      duboyishishi: "",
      result: {},
      radio: "",
      buyeliang: "",
      xuexibaobirongNanBYL: "",
      xuexibaobirongNvBYL: "",
      tizhongjianqingBYL: "",
      xuenanongduNanBYL: "",
      xuenanongduNvBYL: "",
      showPicker: false,
    };
  },
  components: {
    FormulaBanner,
    MyPatient,
    FormulaReferences,
    FormulaExplain,
    CalculationResults,
  },
  async created() {
    await this.getTheFormula();
    await this.showRecord();
  },
  watch: {
    jisuanfangshi: {
      immediate: true,
      handler: function() {
        this.show = false;
      },
    },
    xuexibaobirong: {
      immediate: true,
      handler: function() {
        this.show = false;
      },
    },
    xiantizhong: {
      immediate: true,
      handler: function() {
        this.show = false;
      },
    },
    patientSex: {
      immediate: true,
      handler: function() {
        this.show = false;
      },
    },
    yuantizhong: {
      immediate: true,
      handler: function() {
        this.show = false;
      },
    },
    xuenanongdu: {
      immediate: true,
      handler: function() {
        this.show = false;
      },
    },
  },

  methods: {
    onConfirm(value) {
      this.jisuanfangshi = value;
      this.showPicker = false;
      // console.log("值",value)
    },
    onCancel() {
      this.showPicker = false;
    },
    jisuanClick() {
      this.showPicker = true;
    },
    // 回显数据
    async showRecord() {
      if (
        this.$router.currentRoute.query.location == "YXGS_RECORD_LIST" &&
        this.$router.currentRoute.query.logId
      ) {
        const data = {
          id: this.$router.currentRoute.query.logId,
        };
        const response = await getFormulaHuiXian(data);
        if (response.code === 0) {
          // console.log("回显数据",response.data)
          this.jisuanfangshi = response.data.content.jisuanfangshi;
          this.xuexibaobirong =
            this.jisuanfangshi == "按血细胞比容"
              ? response.data.content.xuexibaobirong.value
              : "";
          this.xiantizhong = response.data.content.xiantizhong.value;
          this.patientSex =
            this.jisuanfangshi !== "按体重减轻"
              ? response.data.content.xingbie.value == "男"
                ? 1
                : 2
              : "";
          this.yuantizhong =
            this.jisuanfangshi == "按体重减轻"
              ? response.data.content.yuantizhong.value
              : "";
          this.xuenanongdu =
            this.jisuanfangshi == "按血钠浓度"
              ? response.data.content.xuenanongdu.value
              : "";
          this.countText = `补液量： ${response.data.result.buyeliang.value} ml `;

          this.$nextTick(() => {
            this.show = true;
          });
          // this.yuantizhong = response.data.content.yuantizhong.value
          // this.xiantizhong = response.data.content.xiantizhong.value
          // this.xuexibaobirong = response.data.content.xuexibaobirong.value
          // this.xuenanongdu = response.data.content.xuenanongdu.value
          // if(response.data.content.xingbie.value == '女'){
          //   this.patientSex = '2'
          // }else{
          //   this.patientSex = '1'
          // }
          // if (response.data.patientId) this.selectValue = {id: response.data.patientId, name: response.data.patientName};this.patientId = response.data.patientId
          // // 计算结果
          // this.tizhongjianqingBYL = response.data.result.bYtizhongjianqing.value
          // response.data.result.bYxuenanongdu.xingbie == '女'?this.xuexibaobirongNvBYL = response.data.result.bYxuexibaobirong.value:""
          // response.data.result.bYxuenanongdu.xingbie == '男'?this.xuexibaobirongNanBYL = response.data.result.bYxuexibaobirong.value:""
          // response.data.result.bYxuenanongdu.xingbie == '女'?this.xuenanongduNvBYL = response.data.result.bYxuenanongdu.value:""
          // response.data.result.bYxuenanongdu.xingbie == '男'?this.xuenanongduNanBYL = response.data.result.bYxuenanongdu.value:""

          // var data1 =  `根据体重减轻量估算：${ this.tizhongjianqingBYL } ml`
          // var data2 =  `根据血细胞比容：${ this.xuexibaobirongNanBYL } ml`
          // var data3 =  `根据血细胞比容：${ this.xuenanongduNvBYL } ml`
          // var data4 =  `根据血钠浓度计算：${ this.xuenanongduNvBYL } ml`
          // var data5 =  `根据血钠浓度计算：${ this.xuenanongduNanBYL } ml`
          // this.patientSex == 1? this.countTextList.push(data1,data2,data5) : this.countTextList.push(data1,data3,data4)
        } else {
          Toast.fail(response.message);
        }
      }
    },
    // 获取公式
    async getTheFormula() {
      // console.log("路由",router)
      var data = router.currentRoute.path.substring(
        9,
        router.currentRoute.path.Length
      );
      const response = await getFormula(
        this.$router.currentRoute.query.channel,
        data
      );
      if (response.code === 0) {
        this.StandardDescription = response.data.explain; // 存储标准说明
        this.references = response.data.references; // 参考文献
        this.title = response.data.chineseName; //公式名称
        this.formulaId = response.data.id;
        this.columnCode = response.data.columnCode;
        this.formulaCode = response.data.code;
        // console.log("公式数据",response.data)
      } else {
        Toast.fail(response.message);
      }
    },
    async onSubmit() {
      let content = {};
      let result = {};
      // 计算方式--体重减轻
      switch (this.jisuanfangshi) {
        case "按体重减轻":
          content = {
            jisuanfangshi: this.jisuanfangshi,
            yuantizhong: { value: this.yuantizhong, unit: "kg" },
            xiantizhong: { value: this.xiantizhong, unit: "kg" },
            // xuexibaobirong:{value:this.xuexibaobirong , unit:"范围0~1"},
            // xuenanongdu:{value:this.xuenanongdu , unit:"mmol/L"},
            // xingbie:{value:this.patientSex == '1'? '男':'女'}
          };
          // 1.根据体重减轻量估算 补液量(ml) =[原体重(kg)-现体重(kg)]x(30~40ml/kg)
          this.tizhongjianqingBYL =
            (this.yuantizhong - this.xiantizhong) * 30 +
            " ~ " +
            (this.yuantizhong - this.xiantizhong) * 40;
          this.countText = `补液量：${this.tizhongjianqingBYL} ml`;
          result = {
            buyeliang: {
              value: this.tizhongjianqingBYL.replace(" ml", ""),
              unit: "ml",
            },
            DisplayResults: this.countText,
          };
          break;
        case "按血细胞比容":
          content = {
            jisuanfangshi: this.jisuanfangshi,
            xiantizhong: { value: this.xiantizhong, unit: "kg" },
            xuexibaobirong: { value: this.xuexibaobirong, unit: "范围0~1" },
            xingbie: { value: this.patientSex == "1" ? "男" : "女" },
          };
          //   男性:
          this.xuexibaobirongNanBYL = (
            ((this.xuexibaobirong - 0.48) / 0.48) *
            this.xiantizhong *
            200
          ).toFixed(2);
          //   女性:
          this.xuexibaobirongNvBYL = (
            ((this.xuexibaobirong - 0.42) / 0.42) *
            this.xiantizhong *
            200
          ).toFixed(2);
          this.countText = `补液量：${
            this.patientSex == "1"
              ? this.xuexibaobirongNanBYL
              : this.xuexibaobirongNvBYL
          } ml`;
          result = {
            //结果
            buyeliang: {
              value:
                this.patientSex == "1"
                  ? this.xuexibaobirongNanBYL
                  : this.xuexibaobirongNvBYL,
              unit: "ml",
            },
            DisplayResults: this.countText,
          };
          break;
        case "按血钠浓度":
          content = {
            jisuanfangshi: this.jisuanfangshi,
            xiantizhong: { value: this.xiantizhong, unit: "kg" },
            xuenanongdu: { value: this.xuenanongdu, unit: "mmol/L" },
            xingbie: { value: this.patientSex == "1" ? "男" : "女" },
          };
          //   男性
          this.xuenanongduNanBYL =
            4 * this.xiantizhong * (this.xuenanongdu - 142);
          //   女性
          this.xuenanongduNvBYL =
            3 * this.xiantizhong * (this.xuenanongdu - 142);
          this.countText = `补液量：${
            this.patientSex == "1"
              ? this.xuenanongduNanBYL
              : this.xuenanongduNvBYL
          } ml`;
          result = {
            //结果
            buyeliang: {
              value:
                this.patientSex == "1"
                  ? this.xuenanongduNanBYL
                  : this.xuenanongduNvBYL,
              unit: "ml",
            },
            DisplayResults: this.countText,
          };

          break;
      }
      var data = {};
      data = {
        columnCode: this.columnCode,
        patientId: this.patientId,
        formulaId: this.formulaId,
        formulaCode: this.formulaCode,
        content: content,
        result: result,
        channel: this.$route.query.channel,
        location: this.$route.query.location,
        id:
          this.$router.currentRoute.query.location === "YXGS_RECORD_LIST"
            ? this.$router.currentRoute.query.logId
            : null,
      };
      // console.log("查看数据",data)
      // 连接口
      const response = await postFormula(data);
      if (response.code === 0) {
        Toast.success("提交成功");
        this.show = true;
      } else {
        Toast.fail(response.message);
      }
    },
    userChange(data) {
      this.selectValue = data;
      // console.log("全部数据",this.selectValue)
      this.patientId = this.selectValue.id;
      this.patientSex = this.selectValue.sex;
    },
    // 开参考文献链接
    openLink(u) {
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid =
        userAI.indexOf("Android") > -1 || userAI.indexOf("Linux") > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u);
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.index {
  background: #f6f6f6;
  padding: 10px 15px;
  height: 100%;
  // overflow:scroll ;
  .StandardDescription {
    margin-top: 10px;
    .top_nav {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil {
      width: 100%;
      padding: 15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
      .references {
        margin-bottom: 15px;
      }
    }
    .StandardDeatil2 {
      width: 100%;
      padding: 15px 10px;
      padding-bottom: 1px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
      .references {
        margin-bottom: 15px;
      }
    }
  }
  .top_nav {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody {
    background-color: #fff;
    padding: 0px 0px 10px 0px;
    .CalculationResultsBox {
      padding: 0 8px;
      .CalculationResults {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }
      .CalculationDetail {
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 10px;
        background-color: #f6f6f6;
        // height: 16px;
        span {
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 45px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName {
      font-size: 16px;
      i {
        color: red;
      }
    }
    .information1 {
      font-size: 16px;
      // margin-right:4px;
      .PleaseSelect {
        color: #b9b9b9;
      }
      .vanCell {
        width: 50px;
        padding: 0;
        height: 100%;
      }
      .vanCell1 {
        width: 125px;
        padding: 0;
        height: 100%;
      }
    }
    .information {
      font-size: 16px;
      margin-right: 4px;
      .PleaseSelect {
        color: #b9b9b9;
      }
      .vanCell {
        width: 50px;
        padding: 0;
        height: 100%;
      }
      .vanCell1 {
        width: 125px;
        padding: 0;
        height: 100%;
      }
    }
    .vBotton {
      width: 60px;
    }
    .unit {
      height: 45px;
      //   line-height: 45px;
      display: flex;
      align-items: center;
      font-size: 16px;
      // width:20px;
    }
    .vanIcon {
      font-size: 13px;
      color: #a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding: 0px 0px;
}
::v-deep .van-field__control {
  height: 45px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 25px;
  left: 10px;
}
::v-deep p {
  padding: 0;
  margin: 0;
}
</style>
